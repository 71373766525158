import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipTippyModule } from '../mat-tooltip-tippy/mat-tooltip-tippy.module';
import { EstimationStatisticsGraphComponent } from './estimation-statistics-graph.component';

@NgModule({
  declarations: [EstimationStatisticsGraphComponent],
  imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, MatTooltipTippyModule, CustomPipesModule],
  exports: [EstimationStatisticsGraphComponent],
})
export class EstimationStatisticsGraphModule {}
